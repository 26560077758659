.main-page-slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;
}

.main-page-slider img {
	min-height: 770px;
	width: 100%;
	object-fit: cover;
}

.main-page-slider .carousel-caption {
	top: 35%;
}

.main-page-slider h2 {
	color: white;
	@apply --op4;
	font-size: 48px;
	text-transform: uppercase;
}

.main-page-slider p {
	color: white;
	@apply --op7;
	font-size: 36px;
	line-height: 1;
	margin-bottom: 40px;
}

.main-page-slider .item a {
	display: inline-block;
	position: relative;
	color: white;
	text-shadow: none;
	@apply --op7;
	font-size: 16px;
	text-transform: uppercase;
	border: 1px solid var(--lightRed);
	border-radius: 2px;
	padding: 10px 30px;
	text-decoration: none;
	background-color: transparent;
	@apply --transitionBackgroundColor;
}

.main-page-slider .item a:hover {
	background-color: var(--lightRed);
}

.main-page-slider .item a:active {
	top: 1px;
}

.main-page-slider .carousel-indicators {
	bottom: 17%;
}

.main-page-slider .carousel-indicators li {
	width: 60px;
	height: 20px;
	border-color: var(--grey);
	border-style: solid ;
	border-width: 0 0 3px 0;
	border-radius: 0;
	margin: 20px 0;
	background-color: transparent;
	transition: border-color .3s;
}

.main-page-slider .carousel-indicators li:hover,
.main-page-slider .carousel-indicators .active {
	border-color: var(--lightRed);
}

.main-page-slider .carousel-control span {
	width: 30px;
	height: 64px;
	display: block;
	position: absolute;
	top: 50%;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABACAMAAAA6ajcXAAAAYFBMVEUAAADr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+vr6+uWmhtYAAAAIHRSTlMA/vrjTDgZDgXue9TDsYBkYcatlyiU1pgn8JtnJdcqKeETWzQAAACWSURBVDjL1dVZDoMgFEBRH4KCQweHWq1t979Ld3BN3gsf8nsTAgcIRTMXND7SYL/JA/smHnvvAuX4TDv1+lVW1Kuyran/1y5SD27A5XthvruRbzHzvXPyTcnKN16ar700H2eePLhevTFmYdTYpUl9oKN4vkzZsAYTFj9gvr9qrCUj1qzG+q0GrO8J1q7G2gxY/MWefNAHUawIVZOO2A4AAAAASUVORK5CYII=');
}

.main-page-slider .left span {
	left: 20%;
	transform: rotate(180deg);
}

.main-page-slider .right span {
	right: 20%;
}





@media (width <= 991px) {
	.main-page-slider .carousel-caption {	top: 45%;	}
	.main-page-slider .carousel-indicators { bottom: 10%; }
	.main-page-slider h2 { font-size: 36px;	}
	.main-page-slider p {	font-size: 24px; }
}





@media (width <= 767px) {
	.main-page-slider img { min-height: 600px; }
	.main-page-slider .carousel-caption {	top: 35%;	}
	.main-page-slider .carousel-indicators { bottom: 0%; }
}





@media (width <= 479px) {
	.main-page-slider .carousel-caption {	top: 29%;	}
	.main-page-slider .carousel-indicators { bottom: 0%; }
}