.block-item__slider {
	flex-basis: 50%;
}

.block-item__slider .carousel-inner {
	width: 100%;
	margin-bottom: 10px;
}

.block-item__slider .carousel-indicators {
	position: static;
	display: flex;
	justify-content: space-around;
	margin: 0;
	width: 100%;
	cursor: pointer;	
}

.block-item__slider .carousel-indicator {
	flex-basis: 24%;
	background-color: transparent;
	position: relative;	
	@apply --transitionOpacityStart;
}

.block-item__slider .carousel-indicator:hover {
	@apply --transitionOpacityEnd;
}

.block-item__slider .carousel-indicators .active {
	width: auto;
	height: auto;
}

.block-item__slider .carousel-indicator::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";	
	border: 0px solid var(--red);
	opacity: 0;
	transition: border-width .3s, opacity .3s;
}

.block-item__slider .carousel-indicators .active::after {
	border: 10px solid var(--red);
	opacity: 1;
	transition: border-width .3s, opacity .3s;
}

.block-item__slider .carousel-indicator img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}




@media (width <= 1199px) {
	.block-item__slider .block-item__slider {
		flex-basis: 100%;
		margin-bottom: 40px;
	}

	.block-item__slider .block-item__slider .carousel {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
}