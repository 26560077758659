html.remodal-is-locked body {
	/* overflow: auto; */
}

.remodal-overlay {
	background: rgba(21, 61, 146, 0.5);
}

.remodal-close {
	color: var(--red);
	border: 1px solid var(--red);
	border-radius: 50%;
	width: 20px;
	height: 20px;
	top: 10px;
	right: 10px;
	@apply --transitionOpacityStart;
}

.remodal-close:hover,
.remodal-close:focus {
	color: var(--red);
	@apply --transitionOpacityEnd;
}

.remodal-close::before {
	width: 18px;
	line-height: 18px;
	font-size: 18px;
}

.send-request {
	background-color: var(--grey);
}

.send-request h2 {
	text-align: center;
	@apply --op7;
	font-size: 40px;
	color: var(--darkBlue);
}

.send-request__content {
	margin-top: 40px;
}

.send-request .form-group {
	max-width: 560px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
}

.send-request .form-group input,
.send-request .form-group textarea {
	background-color: white;
	border: 1px solid #a6a0a0;
	border-radius: 2px;
	color: var(--orange);
	@apply --op4;
	font-size: 22px;
}

.send-request .form-group input {
	height: 50px;
	animation: order-autofill both .5s;
}

.send-request .form-group textarea {
	min-height: 100px;
	animation: order-autofill both .5s;
}

@keyframes order-autofill {
	to {
		box-shadow: 0 0 0 99px white inset;
		-webkit-text-fill-color: var(--darkBlue);
	}
}

.send-request .form-control:focus {
	outline: 2px solid var(--red);
	outline-offset: -3px;
}

.send-request .form-group textarea {
	resize: vertical;
}

.send-request .order-btn {
	background-color: #ff3600;
	border-radius: 2px;
	border: 1px solid #ff3600;
	@apply --op4;
	font-size: 22px;
	text-transform: uppercase;
	color: #FFF;
	padding: 5px 25px;
	transition: color .3s, background-color .3s;
	margin-bottom: 30px;
}

.send-request .order-btn:hover {
	background-color: #FFF;
	color: #ff3600;
}

.send-request .order-btn.disabled {
	cursor: no-drop;
}

.send-request .order-btn:not(.disabled) {
	cursor: pointer;
	background-color: var(--blue);
	border-color: var(--blue);
}

.send-request .order-btn:not(.disabled):hover {
	background-color: #FFF;
	color: var(--blue);
}









@media (width >= 1200px) {
	.remodal {
		max-width: 900px;
	}
}





@media (width <= 767px) {
	.send-request h2 {
		font-size: 30px;
	}
}





@media (width <= 479px) {

	.remodal {
		padding: 20px;
	}
	
	.send-request h2 {
		font-size: 20px;
		margin-left: 0;
		margin-bottom: 0;
	}

	.send-request__content {
		margin-top: 20px;
	}

	.send-request .order-btn {
		margin-bottom: 15px;
	}
}