.block--pagination{
	margin-bottom: 80px;
}

.block--pagination ul {
	padding-left: 0;
	list-style: none;
	width: 100%;
	text-align: center;
}

.block--pagination li {
	display: inline-block;
	text-align: center;
	margin: 0 10px;
}


.block--pagination a,
.block--pagination span {
	text-decoration: none;
	color: var(--dark-gold);
	background-color: transparent;
	@apply --op7;
	font-size: 20px;
	text-transform: uppercase;
	padding: 5px 12px;
	transition: color .3s, background-color .3s;
}

.block--pagination li.active a,
.block--pagination li:hover a,
.block--pagination li.active span,
.block--pagination li:hover span {
	color: white;
	background-color: var(--red);
}

.block--pagination .active:hover a {
	cursor: default;
}





@media (width <= 991px) {
	.block--pagination{
		margin-bottom: 40px;
	}
	.pagination {
		margin: 5px 0;
	}
}
