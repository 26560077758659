.block--item__description {
	flex-basis:50%;
	background-color: #FFF;
	padding-top: 45px;
}

.block--item__description h1,
.block--item__description h3 {
	@apply --op7;
	font-size: 20px;
	text-transform: uppercase;
	text-align: left;
	padding-left: 45px;
	padding-right: 45px;
}

.block--item__description h1 {
	color: var(--red);
	margin: 0;
}

.block--item__description h3 {
	color: var(--black);
	margin-top: 10px;
	margin-bottom: 20px;
}

.block--item__description .pdf-link {
	text-decoration: none;
	background-color: var(--middleBlue);
	border-radius: 2px;
	border: 1px solid var(--middleBlue);
	color: white;
	@apply --op7;
	font-size: 16px;
	text-transform: uppercase;
	padding: 10px 25px 10px 50px;
	display: inline-block;
	position: relative;
	margin-right: 20px;
	margin-left: 45px;
	margin-bottom: 20px;
	transition: background-color .3s, color .3s;
}

.block--item__description .pdf-link:hover {
	background-color: white;
	color: var(--middleBlue);
}

.block--item__description .pdf-link:active {
	top: 1px;
}

.block--item__description .pdf-link::after {
	position: absolute;
	top: 8px;
	left: 12px;
	content: "";
	width: 27px;
	height: 27px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAMAAAC6CgRnAAABhlBMVEUAAADiV0ziV0zhWEzIQzfvTULiWExQq5g+sp3qUETiV0w4tqE9s589s5/hV00+rpfaYlrEQDXDPzNBr5nFQTQ9s54L4NLiV0wL49g7tJ7cgXs4tqE4tqHsVUvhWE3rU0jgXVQ+rpY5taDdWk/tVk29PC7gWU7mWk/lVUv/OSsvtpvTTD//OCwutp8/s5zcW09Asp03taE7sqC3b2a+c2nKaGVsloZ0oZnKZVbEQTJLq5fIQzfiV0w8s54rrJXiU0jhTkM9tKD64d/lbGLgVkrhUET////jVUu5OCv308/qhHvndWvkYFbpXFHgSj7GQjbd8u787uz76Of1w7/wpZ9DrZfsjYXkZVrgSz+1Nim0NSiwMiT6/fz42Nb3z8xrxbU4sZw1sJrriIHofHToenHoW1HoUkfJRDjfRDezNSizNCeuMCT2+/v99vX99PTi8/D76un1yMSI0MOH0MN/zb57y710yblxyLjxsKrxrqnwq6Xvn5kbpo3ndGvcU0jgSj/sRTrDQTS5/STYAAAAPHRSTlMA8eujCPzbJ/7+9u/i3te/u7axsK2mXVRLPiTz8uzr2tTFubm4squppqWako2Df3psV0tHLy4hGRcWFAx0fvVkAAABXElEQVQoz3XSZ1PCQBAG4ENFQKog1d5775pLJYkUkd6kd+y9139ucIZgEnxvbr88szO7Mwus3TJB+roAn25EEOeBsYU9Yjvp5VEmsSSPEkvWE8b9f6yeSCYaA0kN+T4+5d7kbjv7qB5xqc63M6R8yKU8zZs0nS2L+2gE98UxrtBi8xS8NOUuIlzxYAKjqQAZ8roCFy81V/A1LjC85PJeRV1ud9CT8dQQoVHnZCiWLuFkNEMWcIRl2Rne3gNRLx0MP5Gx9DOlkus6x5ebhlHhTxwLkddFXzj2ZdjcUij2msZp47MYhjjlC8Ci71ePrUp3rxjAXIRAUQIuSUy+YYlAeHufh3BNbMM7owSEzCUKoUlk7JRiAL1JMczZI6oR90041Oidn2H8WVQrtkGHnsijKf9bjjCL70y+buNmecjmoNIuvs/KyPai8neHFQCsOlnHnwypZoHNpNGa7QD8AB+ikOEBk8JbAAAAAElFTkSuQmCC');
}

.block--item__description .order-item-link {
	background-color: var(--lightRed);
	border-radius: 2px;
	border: 1px solid var(--lightRed);
	padding: 10px 25px;
	display: inline-block;
	color: white;
	text-decoration: none;
	@apply --op7;
	font-size: 16px;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 20px;
	margin-left: 45px;
	transition: background-color .3s, color .3s;
}

.block--item__description .order-item-link:hover {
	background-color: #FFF;
	color: var(--lightRed);
}

.block--item__description .order-item-link:active {
	top: 1px;
}

.block--item__description p {
	color: var(--black);
	@apply --op4;
	font-size: 14px;
	padding-left: 45px;
	padding-right: 45px;
}

.need-help {
	margin-top: 40px;
	margin-bottom: -10px;
	background-color: var(--middleBlue);
}

.need-help h2 {
	background-color: var(--blue);
	color: white;
	@apply --op7;
	font-size: 22px;
	text-transform: uppercase;
	padding: 10px;
	margin-bottom: 0;
}

.need-help p {
	color: white;
	@apply --op7;
	font-size: 20px;
	padding: 10px;
	text-align: center;
	margin-bottom: 0;
}

.need-help h5,
.need-help a {
	@apply --op7;
	font-size: 22px;
	color: var(--grey);
}

.need-help h5 {
	text-align: center;
	padding-bottom: 15px;
}

.need-help a {
	text-decoration: none;
	margin: 0 5px;
	@apply --transitionOpacityStart;
}

.need-help a:hover {
	@apply --transitionOpacityEnd;
}





@media (width <= 1199px) {
	.block-item__slider {
		margin-bottom: 40px;
		flex-basis: 100%;
		max-width: 585px;
	}
	.block--item__description {
		flex-basis: 100%;
	}
}




@media (width <= 641px) {

	.block--item__description .order-item-link {
		margin-left: 45px;
		margin-right: 45px;
	}

	.block--item__description .pdf-link {
		margin-right: -25px;
	}
	.need-help a {
		display: block;
		margin: 10px;
	}
}





@media (width <= 479px) {
	.block--item__description h1,
	.block--item__description h3,
	.block--item__description p {
		padding-left: 20px;
		padding-right: 20px;
	}

	.block--item__description .pdf-link {
		margin-left: 20px;
		margin-right: 0;
	}

	.block--item__description .order-item-link {
		margin-left: 20px;
		margin-right: 0;
	}
} 