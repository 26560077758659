html,
body {
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: var(--grey);
	min-width: 320px;
}

header,
main {
	flex: 1 0 auto;
}

footer {
	flex: 0 0 auto;
}

.site-main {
	margin-top: 40px;
}

.site-main.space-plus {
	margin-top: 133px;
}

h1,h2 {
	color: var(--black);
	@apply --op7;
	font-size: 30px;
	text-transform: uppercase;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 40px;
}

.block--text .ya-share2__list {
	margin-top: 30px;
	margin-bottom: 0;
	padding-left: 0;
}

.block--text .ya-share2__list li {
	position: static;
}

.block--text .ya-share2__list li::before {
	content: none;
}





@media (width <= 479px) {
	h1,h2 {
		font-size: 24px;
	}
}