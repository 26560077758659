.block--intro {
	margin-bottom: 100px;
	position: relative;
	z-index: 1;
}

#mainPageSlider + .site-main .block--intro {
	margin-top: 330px;
}

.block--intro__wrapper {
	position: relative;
	display: flex;
	flex-wrap: wrap;	
}

.block--intro__text,
.block--intro__img {
	flex-basis: 65%;
	box-shadow: 0 6px 13px rgba(34, 2, 2, 0.1);
}

.block--intro__text {
	background-color: #FFF;
	padding: 45px;
	width: 65%;
	pointer-events: none;
}

.block--intro__text h2 {
	color: var(--red);
	@apply --op7;
	font-size: 20px;
	text-transform: uppercase;
	margin-top: 0;
	text-align: left;
}

.block--intro__text p {
	color: var(--black);
	@apply --op4;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 10px;
}

.block--intro__img {
	width: 65%;
	position: absolute;
	bottom: -45px;
	right: 0;
	z-index: -1;
}

.block--intro__img img {
	width: 100%;
	height: 100%;
	background-size: cover;
}




@media (width <= 991px) {
	.block--intro {
		margin-bottom: 50px;
	}
	
	.block--intro__text,
	.block--intro__img {
		flex-basis: 95%;
		width: 95%;
	}

	.block--intro__img {
		position: static;
	}

	.block--intro__text {
		margin-top: -20px;
		margin-left: 5%;
	}
}





@media (width <= 767px) {
	.block--intro__text,
	.block--intro__img {
		flex-basis: 100%;
		width: 100%;
	}

	.block--intro__img {
		position: static;
	}

	.block--intro__text {
		margin-top: 0;
		margin-left: 0;
	}
}





@media (width <= 641px) {
	.block--intro__text {
		padding: 25px;
	}
}





@media (width <= 479px) {
	.block--intro__text h2 {
		text-align: center;
	}
} 