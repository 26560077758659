.block--articles__item {
	flex-basis: 100%;
	margin-bottom: 100px;
	position: relative;
	z-index: 1;
}

.block--articles__wrapper {
	position: relative;
	display: flex;
	flex-wrap: wrap;	
}

.block--articles__text,
.block--articles__img {
	flex-basis: 65%;
	box-shadow: 0 6px 13px rgba(34, 2, 2, 0.1);
}

.block--articles__text {
	background-color: #FFF;
	padding: 45px;
	width: 65%;
}

.block--articles__text h2 {
	color: var(--red);
	@apply --op7;
	font-size: 20px;
	text-transform: uppercase;
	margin-top: 0;
	text-align: left;
	margin-bottom: 20px;
}

.block--articles__text span {
	display: block;
	@apply --op7;
	font-size: 14px;
	color: #394759;
	margin-bottom: 20px;
}

.block--articles__text p {
	color: var(--black);
	@apply --op4;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 20px;
}

.block--articles__text a {
	background-color: var(--blue);
	border: 1px solid var(--blue);
	border-radius: 2px;
	display: inline-block;
	padding: 10px 20px;
	color: white;
	@apply --op7;
	font-size: 16px;
	text-transform: uppercase;
	text-decoration: none;
	transition: color .3s, background-color .3s;
	position: relative;
}

.block--articles__text a:hover {
	background-color: #FFF;
	color: var(--blue);
}

.block--articles__text a:active {
	top: 1px;
}

.block--articles__img {
	width: 65%;
	position: absolute;
	bottom: -45px;
	right: 0;
	z-index: -1;
}

.block--articles__img img {
	width: 100%;
	height: 100%;
	background-size: cover;
}




@media (width <= 991px) {
	.block--articles__item {
		margin-bottom: 50px;
	}
	
	.block--articles__text,
	.block--articles__img {
		flex-basis: 95%;
		width: 95%;
	}

	.block--articles__img {
		position: static;
	}

	.block--articles__text {
		margin-top: -20px;
		margin-left: 5%;
	}
}





@media (width <= 767px) {
	.block--articles__text,
	.block--articles__img {
		flex-basis: 100%;
		width: 100%;
	}

	.block--articles__img {
		position: static;
	}

	.block--articles__text {
		margin-top: 0;
		margin-left: 0;
	}
}





@media (width <= 641px) {
	.block--articles__text {
		padding: 25px;
	}
}
 