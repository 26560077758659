.headroom {
    transition: transform .25s ease-in-out, background-color .25s;
    will-change: transform;
    background-color: transparent;
}

.headroom--not-top {
	position: fixed;
	z-index: 10;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	background-color: var(--blue);
}

.headroom--pinned { transform: translateY(0); }
.headroom--unpinned { transform: translateY(-100%); }

.animated {
    animation-duration: .5s;
    animation-fill-mode: both;
    will-change: transform,opacity;
}


@keyframes slideDown {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(0); }
}

.animated.slideDown { animation-name: slideDown; }

@keyframes slideUp {
    0% { transform: translateY(0); }
    100% { transform: translateY(-100%); }
}

.animated.slideUp { animation-name: slideUp; }