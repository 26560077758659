.block--articles-more__item {
	flex-basis: 30%;
	margin-left: 1%;
	margin-right: 1%;
	margin-bottom: 100px;
	@apply --boxShadow;
	background-color: #FFF;
}

.block--articles-more__item:hover {
	@apply --boxShadowHover;
}

.block--articles-more__item h3 {
	color: var(--red);
	@apply --op7;
	font-size: 20px;
	text-transform: uppercase;
	margin-top: 20px;
	text-align: left;
	margin-bottom: 20px;
	padding: 0 20px;
}

.block--articles-more__item span {
	display: block;
	@apply --op7;
	font-size: 14px;
	color: #394759;
	margin-bottom: 20px;
	padding: 0 20px;
}

.block--articles-more__item p {
	color: var(--black);
	@apply --op4;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 20px;
	padding: 0 20px;
}

.block--articles-more__item a:first-of-type {
	width: 100%;
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.block--articles-more__item a:first-of-type:hover {
	@apply --transitionOpacityEnd;
}

.block--articles-more__item a:last-of-type {
	background-color: var(--blue);
	border: 1px solid var(--blue);
	border-radius: 2px;
	display: inline-block;
	padding: 10px 20px;
	color: white;
	@apply --op7;
	font-size: 16px;
	text-transform: uppercase;
	text-decoration: none;
	transition: color .3s, background-color .3s;
	position: relative;
	margin: 0 20px 20px;
}

.block--articles-more__item a:last-of-type:hover {
	background-color: #FFF;
	color: var(--blue);
}

.block--articles-more__item a:last-of-type:active {
	top: 1px;
}

.block--articles-more__item img {
	width: 100%;
	height: auto;
	background-size: cover;
}




@media (width <= 991px) {
	.block--articles-more__item {
		margin-bottom: 50px;
	}
}





@media (width <= 767px) {
	.block--articles-more__item {
		flex-basis: 100%;
		width: 100%;
		max-width: 480px;
	}
}