.block--contacts__title {
	padding-bottom: 100px;
}

.block--contacts__title h1 {
	color: #394759;
	@apply --op7;
	font-size: 30px;
	text-transform: uppercase;
}

.block--contacts__title__wrapper {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.block--contacts__title__item {
	text-align: left;
}

.block--contacts__title__wrapper h2 {
	color: var(--red);
	@apply --op7;
	font-size: 20px;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 20px;
}

.block--contacts__title__wrapper p,
.block--contacts__title__wrapper a {
	color: var(--black);
	@apply --op4;
	font-size: 20px;
}

.block--contacts__title__wrapper span,
.block--contacts__title__wrapper a {
	display: block;
}

.block--contacts__title__wrapper a {
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.block--contacts__title__wrapper a:hover {
	@apply --transitionOpacityEnd;
}




@media (width <= 767px) {
	.block--contacts__title__item {
		flex-basis: 100%;
		padding: 0 20px;
		text-align: center;
		margin-bottom: 20px;
	}

		.block--contacts__title__item h2 {
			text-align: center;
			margin-bottom: 10px;
		}
}