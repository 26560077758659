.block--catalogue {
	margin-bottom: 60px;
}

.block--catalogue h1 {
	color: #394759;
	@apply --op7;
	font-size: 30px;
	text-transform: uppercase;
}

.block--catalogue--no-img {
	margin-bottom: 0;
}

.block--catalogue--no-img ~ .block--pagination {
	margin-bottom: 0;
}

.block--catalogue--no-img ~ .block--addition {
	margin-top: 20px;
}





@media (width <= 479px) {
	.block--catalogue h1 {
		font-size: 22px;
	}
}