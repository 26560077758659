.block--articles {}

.block--articles h1 {
	color: #394759;
	@apply --op7;
	font-size: 30px;
	text-transform: uppercase;
}

.block--articles__wrapper {
	display: flex;
	flex-wrap: wrap;
}