.alert {text-align: center;}
.alert a {color: #a94442 !important; font-weight: bold; text-decoration: underline;}
.alert a:hover {text-decoration: none;}

.pagination > li > a,
.pagination > li > span {
	background: none;
	border: none;
	color: #f03400;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
	background: none;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #f03400;
	border: none;
}