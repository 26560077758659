.order-item {
	background-color: var(--grey);
}

.order-item h2 {
	text-align: center;
	@apply --op7;
	font-size: 40px;
	color: var(--darkBlue);
}

.order-item__content {
	margin-top: 40px;
}

.order-item .form-group {
	max-width: 560px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
}

.order-item .form-group input,
.order-item .form-group textarea {
	background-color: white;
	border: 1px solid #dabc8e;
	border-radius: 2px;
	color: var(--orange);
	@apply --op4;
	font-size: 22px;
}

.order-item .form-group input {
	height: 50px;
	animation: order-autofill both .5s;
}

.order-item .form-group textarea {
	min-height: 100px;
	animation: order-autofill both .5s;
}

@keyframes order-autofill {
	to {
		box-shadow: 0 0 0 99px #fdf8f0 inset;
		-webkit-text-fill-color: var(--darkBlue);
	}
}

.order-item .form-control:focus {
	outline: 2px solid var(--orange);
	outline-offset: -3px;
}

.order-item .form-group textarea {
	resize: vertical;
}

.order-item .order-btn {
	background-color: var(--red);
	border-radius: 2px;
	border: 1px solid var(--red);
	@apply --op4;
	font-size: 22px;
	text-transform: uppercase;
	color: #FFF;
	padding: 5px 15px;
	transition: color .3s, background-color .3s;
	margin-bottom: 30px;
}

.order-item .order-btn:hover {
	background-color: #FFF;
	color: var(--red);
}

.order-item .order-btn.disabled {
	cursor: no-drop;
}

.order-item .order-btn:not(.disabled) {
	cursor: pointer;
	background-color: var(--blue);
	border-color: var(--blue);
}

.order-item .order-btn:not(.disabled):hover {
	background-color: #FFF;
	color: var(--blue);
}





@media (width <= 767px) {
	.order-item h2 {
		font-size: 30px;
	}
}





@media (width <= 479px) {
	.order-item h2 {
		font-size: 20px;
		margin-left: 0;
		margin-bottom: 0;
	}

	.order-item__content {
		margin-top: 20px;
	}

	.order-item .order-btn {
		margin-bottom: 15px;
	}
}