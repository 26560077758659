.main-page-sections {}

.main-page-sections h2 {
	color: var(--black);
	@apply --op6i;
	font-size: 36px;
	text-align: center;
	margin-bottom: 60px;
	padding: 0 15px;
	text-transform: none;
}

.main-page-sections__wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.main-page-sections__wrapper figure {
	flex-basis: calc(33% - 8px);
	margin: 0 4px 10px;
	position: relative;
	@apply --boxShadow;
}

.main-page-sections__wrapper figure:hover {
	@apply --boxShadowHover;
}

.main-page-sections__wrapper figure > a {
	width: 100%;
}

.main-page-sections__wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.main-page-sections__wrapper figcaption {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(111,108,108,.8);
	padding: 5px 10px;
	overflow: hidden;
}

.main-page-sections__wrapper figcaption::after {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	content: "";
	background-color: var(--red);
	position: absolute;
	will-change: transform;
	transition: transform .3s;
	transform: translateY(100%);
}

.main-page-sections__wrapper figure:hover figcaption::after {
	transform: translateY(0);
}

.main-page-sections__wrapper figcaption a {
	color: white;
	@apply op6;
	font-size: 18px;
	text-transform: uppercase;
	text-decoration: none;
	position: relative;
	z-index: 1;
}





@media (width <= 991px) {
	.main-page-sections__wrapper figure {
		flex-basis: calc(50% - 8px);
	}
}





@media (width <= 641px) {
	.main-page-sections__wrapper {
		justify-content: center;
	}
	.main-page-sections__wrapper figure {
		flex-basis: 100%;
		max-width: 480px;
	}
}