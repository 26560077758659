.block--text img {
	max-width: 100%;
	height: auto;
}

.block--text__wrapper {
	padding: 45px;
	background-color: #FFF;
}

.block--text h2 {
	color: var(--red);
	@apply --op7;
	font-size: 20px;
	text-transform: uppercase;
}

.block--text h3,
.block--text h4 {
	color: var(--black);
	@apply --op7;
	font-size: 18px;
	text-transform: uppercase;
}

.block--text p,
.block--text ul li,
.block--text ol li {
	color: var(--black);
	@apply --op4;
	font-size: 14px;
}

.block--text ul,
.block--text ol {
	margin-bottom: 30px;
	padding-left: 25px;
}

.block--text ul li,
.block--text ol li {
	text-align: left;
	list-style: none;
	position: relative;
	margin-bottom: 10px;
}

.block--text ul li::before {
	position: absolute;
	width: 14px;
	height: 14px;
	top: 3px;
	left: -25px;
	content: "";
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAOVBMVEUAAAA5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1k5R1mNbK6vAAAAEnRSTlMA9rljVkzs4djLxbM0JBwaEhF7Z4ATAAAAQElEQVQI12XPSQ5AIQgDUP93nrX3P6xxBabdvYRAMTe2GRWL1BUjkIfQB6BM5Q/4t9hdr9eVSMOyig5RDS6pXzgt9gLfyGQVnAAAAABJRU5ErkJggg==');
}

.block--text ol {
	counter-reset: my-counter;
}

.block--text ol li {
	margin-left: 20px;
}

.block--text ol li::before {
	position: absolute;
	top: -3px;
	left: -45px;
	content: counter(my-counter);
	counter-increment: my-counter;
	@apply --op7;
	color: white;
	background-color: var(--black);
	padding: 5px 10px;
	font-size: 14px;
	line-height: 1;
}

.block--text .table-bordered {
	background-color: #f4f4f4;
	border: 3px solid #e6e6e6;
	color: var(--black);
}

.block--text .table-bordered > thead > tr > th,
.block--text .table-bordered > tbody > tr > th,
.block--text .table-bordered > tfoot > tr > th,
.block--text .table-bordered > thead > tr > td,
.block--text .table-bordered > tbody > tr > td {
	border: 2px solid transparent;
	border-right: 2px solid white;
}

.block--text .table-bordered > thead > tr,
.block--text .table-bordered > thead > tr:nth-child(even) {
	background-color: #f4f4f4;
}

.block--text .table-bordered > tbody > tr:nth-child(odd) {
	background-color: #fafafa;
}

.block--text .table-bordered > thead {
	@apply --op7;
	font-size: 14px;
	text-transform: uppercase;
}

.block--text .table-bordered > tbody {
	@apply --op4;
	font-size: 14px;
}