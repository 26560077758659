.block--item-more {
	margin-bottom: 40px;
}

.block--item-more h3 {
	text-align: center;
	margin-top: 0;
	margin-bottom: 30px;
	color: #394759;
	@apply --op7;
	font-size: 30px;
	text-transform: uppercase;
}




@media (width <= 991px) {
	.block--item-more .main-page-sections__wrapper {
		justify-content: space-around;
	}
}