.block--item__tabs {
	margin-top: 40px;
	width: 100%;
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs > li > a {
	@apply --op7;
	font-size: 18px;
	text-transform: uppercase;
	color: #606879;
	background-color: transparent;
	transition: color .3s, background-color .3s;
	border-radius: 0;
	border: none;
}

.nav-tabs > li > a:hover {
	color: var(--black);
	background-color: white;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	border-radius: 0;
	border: none;
	cursor: default;
	text-align: center;
}

.tab-content {
	background-color: #FFF;
	padding: 40px;
}





@media (width <= 479px) {
	.tab-content {
		padding: 20px;
	}
} 